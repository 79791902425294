import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconPhoneFilled = ({
  title = 'Phone',
  color = 'dark',
  viewBoxWidth = 14,
  viewBoxHeight = 14,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="m 4.5082606,1.8496503 0.1875,1.5625 c 0.0625,0.375 -0.0625,0.75 -0.375,1 l -1.125,1.125 c 0.875,1.75 2.375,3.25 4.125,4.1250002 l 1.125,-1.1250002 c 0.25,-0.25 0.625,-0.375 1,-0.375 l 1.5624994,0.1875 c 0.625,0.0625 1.125001,0.625 1.125001,1.2500002 V 10.72465 c 0.0625,0.6875 -0.562501,1.25 -1.250001,1.25 C 5.5707606,11.66215 1.2582605,7.3496503 0.94576049,2.0371503 0.88326049,1.3496504 1.5082605,0.7246504 2.1957606,0.7246504 h 1.0625 c 0.6875,0 1.1875,0.5 1.25,1.1249999 z"
      fill={colors[color]}
      fillRule="nonzero"
    />
  </SvgIcon>
);

export default IconPhoneFilled;
