import * as React from 'react';
import { NewArrival, UpdateOrderForNewArrivalRequestSignature } from 'api/simplyShip';
import { AvailableDates } from '../OrderContext/types';
import { KeyVal } from 'libs/utils/common-types';
import { Address } from 'bundles/App/pages/Account/Addresses/types';

export interface SimplyShipContextType {
  admin: boolean;
  updateOrderForNewArrival: UpdateOrderForNewArrivalRequestSignature;
  initNewArrival: NewArrival;
  newArrival: NewArrival;
  onAddressChange: (address: Address) => void;
  onReadyAtChange: (date: Date) => void;
  onDateChange: (date: Date) => void;
  onTimeChange: (time: string) => void;
  availableDates: AvailableDates;
  availableTimes: string[];
  datesChanged: KeyVal | null;
}

const SimplyShipContext = React.createContext<SimplyShipContextType>(null);

export default SimplyShipContext;
