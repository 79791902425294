import * as React from 'react';
import cn from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  verifyCallback: (response: string) => void;
  reference?: React.RefObject<unknown>;
  hidden?: boolean;
}

const Captcha = ({ hidden, reference, verifyCallback }: Props) => (
  <div className={cn('my-2', hidden && 'hidden')}>
    <ReCAPTCHA ref={reference} sitekey={process.env.RECAPTCHA_SITE_KEY} onChange={verifyCallback} />
  </div>
);

export default Captcha;
