import * as React from 'react';
import cn from 'classnames';
import css from 'styleguide/components/forms/Field/Field.scss';

interface Props {
  Input: React.ReactNode;
  className?: string;
  inputWrapperClassName?: string;
  Note?: React.ReactNode;
  labelPosition?: 'relative' | 'absolute';
  Label?: React.ReactNode;
  Tooltip?: React.ReactNode;
  labelClassName?: string;
}

const FieldWrapper = ({ ...props }: Props) => (
  <div className={cn(css.field, props.className)}>
    {props.Label && (
      <div className="flex">
        <div
          className={cn('flex flex-1', props.labelClassName, {
            [css[`position--${props.labelPosition}`]]: !!props.labelPosition,
          })}
        >
          {props.Label}
        </div>
        <div className="flex">{props.Tooltip && props.Tooltip}</div>
      </div>
    )}
    <div className={cn(css.inputWrapper, props.inputWrapperClassName)}>{props.Input}</div>
    {!!props.Note && <div className="mt-2">{props.Note}</div>}
  </div>
);
export default FieldWrapper;
