export const products = [
  {
    label: 'Document Printing',
    values: [
      { key: 'Color Copies', label: 'Color Copies' },
      { key: 'Black And White Copies', label: 'Black And White Copies' },
    ],
  },
  {
    label: 'Graphics Printing',
    values: [
      { key: 'Mini Posters', label: 'Mini Posters' },
      { key: 'Posters', label: 'Posters' },
      { key: 'Banners', label: 'Banners' },
    ],
  },
  {
    label: 'Marketing Printing',
    values: [
      { key: 'Flyers', label: 'Flyers' },
      { key: 'Brochures', label: 'Brochures' },
      { key: 'Business Cards', label: 'Business Cards' },
      { key: 'Postcards', label: 'Postcards' },
      { key: 'Folders', label: 'Folders' },
      { key: 'Door Hangers', label: 'Door Hangers' },
      { key: 'Stickers', label: 'Stickers' },
      { key: 'Menus', label: 'Menus' },
      { key: 'Rack Cards', label: 'Rack Cards' },
      { key: 'Bookmarks', label: 'Bookmarks' },
    ],
  },
  {
    label: 'Booklets Printing',
    values: [
      { key: 'Saddle-Stitched Booklets', label: 'Saddle-Stitched Booklets' },
      { key: 'Perfect Bound Books', label: 'Perfect Bound Books' },
      { key: 'Coil Booklets', label: 'Coil Booklets' },
      { key: 'Hardcover Books', label: 'Hardcover Books' },
      { key: 'Generic Book/Booklet', label: 'Generic Book/Booklet' },
      {
        key: 'Undecided',
        label: 'Undecided',
      },
    ],
  },
];

export const dimensions = [
  {
    label: 'Popular Portrait Dimensions',
    values: [
      { key: '8.5"x11"', label: '8.5"x11"' },
      { key: '5.5"x8.5"', label: '5.5"x8.5"' },
    ],
  },
  {
    label: 'Other Portrait Dimensions',
    values: [
      { key: '5"x7"', label: '5"x7"' },
      { key: '6"x9"', label: '6"x9"' },
      { key: '8"x8"', label: '8"x8"' },
      { key: '8"x10"', label: '8"x10"' },
      { key: '9"x12"', label: '9"x12"' },
    ],
  },
  {
    label: 'Landscape Dimensions',
    values: [
      { key: '7"x5"', label: '7"x5"' },
      { key: '8.5"x5.5"', label: '8.5"x5.5"' },
      { key: '9"x6"', label: '9"x6"' },
      { key: '10"x8"', label: '10"x8"' },
      { key: '11"x8.5"', label: '11"x8.5"' },
      { key: '12"x9"', label: '12"x9"' },
    ],
  },
];

export const boxesDimensions = [
  { key: '8" x 6" x 4"', label: '8" x 6" x 4"' },
  { key: '8" x 6" x 6"', label: '8" x 6" x 6"' },
  { key: '8" x 8" x 8"', label: '8" x 8" x 8"' },
  { key: '10" x 6" x 6"', label: '10" x 6" x 6"' },
  { key: '10" x 8" x 8"', label: '10" x 8" x 8"' },
  { key: '10" x 10" x 10"', label: '10" x 10" x 10"' },
  { key: '11.25" x 8.5" x 4"', label: '11.25" x 8.5" x 4"' },
  { key: '11.25" x 8.5" x 8"', label: '11.25" x 8.5" x 8"' },
  { key: '11.25" x 8.5" x 10"', label: '11.25" x 8.5" x 10"' },
  { key: '12" x 8" x 8"', label: '12" x 8" x 8"' },
  { key: '12" x 10" x 6"', label: '12" x 10" x 6"' },
  { key: '12" x 12" x 12"', label: '12" x 12" x 12"' },
  { key: '15" x 12" x 10"', label: '15" x 12" x 10"' },
  { key: '14" x 10" x 10"', label: '14" x 10" x 10"' },
  { key: '14" x 14" x 14"', label: '14" x 14" x 14"' },
  { key: '16" x 10" x 10"', label: '16" x 10" x 10"' },
  { key: '16" x 16" x 16"', label: '16" x 16" x 16"' },
  { key: '8.5"x5.5"', label: '8.5"x5.5"' },
  { key: '9"x6"', label: '9"x6"' },
  { key: '10"x8"', label: '10"x8"' },
  { key: '11"x8.5"', label: '11"x8.5"' },
  { key: '12"x9"', label: '12"x9"' },
  { key: '17.5" x 11.5" x 8.75"', label: '17.5" x 11.5" x 8.75"' },
  { key: '17.5" x 11.5" x 11.5"', label: '17.5" x 11.5" x 11.5"' },
  { key: '18" x 12" x 6"', label: '18" x 12" x 6"' },
  { key: '18" x 12" x 12"', label: '18" x 12" x 12"' },
  { key: '18" x 18" x 18"', label: '18" x 18" x 18"' },
  { key: '24" x 12" x 12"', label: '24" x 12" x 12"' },
];

export const paperType = [
  {
    label: 'Uncoated sheets ("Copy Paper")',
    values: [
      { key: '50lb. text, Standard Copy Paper', label: '50lb. text, Standard Copy Paper' },
      { key: '60 lb. Text, Uncoated', label: '60 lb. Text, Uncoated' },
      { key: '70 lb. Text, Uncoated', label: '70 lb. Text, Uncoated' },
    ],
  },
  {
    label: 'Gloss / Matte Coated Sheets',
    values: [
      { key: '60 lb. Text, Gloss', label: '60 lb. Text, Gloss' },
      { key: '80 lb. Text, Gloss', label: '80 lb. Text, Gloss' },
      { key: '80 lb. Text, Matte', label: '80 lb. Text, Matte' },
      { key: '100 lb. Text, Gloss', label: '100 lb. Text, Gloss' },
      { key: '100 lb. Text, Matte', label: '100 lb. Text, Matte' },
    ],
  },
  {
    label: 'Cover Stock ("Card Stock")',
    values: [
      { key: '80 lb. Cover, Gloss', label: '80 lb. Cover, Gloss' },
      { key: '80 lb. Cover, Matte', label: '80 lb. Cover, Matte' },
      { key: '100 lb. Cover, Gloss', label: '100 lb. Cover, Gloss' },
      { key: '100 lb. Cover, Matte', label: '100 lb. Cover, Matte' },
      { key: '14 pt. "Extra thick" Gloss', label: '14 pt. "Extra thick" Gloss' },
      { key: '14 pt. "Extra thick" Matte', label: '14 pt. "Extra thick" Matte' },
      { key: '16 pt. "Ultra thick" Semi-Gloss', label: '16 pt. "Ultra thick" Semi-Gloss' },
    ],
  },
];

export const boxType = [
  {
    key: 'Shipping Box (Regular Slotted Container - RSC)',
    label: 'Shipping Box (Regular Slotted Container - RSC)',
  },
  { key: 'Mailer Box', label: 'Mailer Box' },
  { key: 'Other', label: 'Other' },
];

export const serviceTypes = [
  {
    key: 'I have a complete file and would like someone to check if it’s print-ready.',
    label: 'I have a complete file and would like someone to check if it’s print-ready.',
  },
  {
    key: 'I have a file, but it needs to be updated by a designer.',
    label: 'I have a file, but it needs to be updated by a designer.',
  },
  {
    key: 'I do not have a print-ready file and need someone to create one for me.',
    label: 'I do not have a print-ready file and need someone to create one for me.',
  },
];
